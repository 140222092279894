import React from 'react'
import { connectHits } from 'react-instantsearch-dom';
import Hit from './inline'

const HitsList = ({ hits, ...rest }) => {
  const currentPath = typeof window !== 'undefined' ? window.location.pathname : ''
  
  return hits.length ? (
    <div className="search-results-index">
      { rest.title ? (<h3>{ rest.title }</h3>) : '' }      
      <ul>
        {
          hits.map(hit => {       
            return currentPath !== hit.url ? (
              <li key={hit.objectID} className="search-result">
                <Hit hit={hit} />
              </li>
            ) : ''
          })
        }
      </ul>
    </div>
  ): ''
}

export default connectHits(HitsList)