import React from 'react'
import defaultImage from '../../../../static/img/honestpaws-logo-circle.png'
import { Link } from 'gatsby'
import { Snippet } from 'react-instantsearch-dom';
import { isExternalUrlOrHash, slashLink } from "../../../helpers"

const InlineHit = ({hit, ...rest}) => {
  const innerHtml = (
    <>
      <img className="lazyload search-result-image" src={`${hit.image ? hit.image : defaultImage}?w=128`} />
      <strong>{hit.title}</strong>      
    </>
  )
  const elementClassName = 'd-flex search-result-image'
  
  return isExternalUrlOrHash(hit.url) ? (
    <a href={slashLink(hit.url)} rel="noreferrer" target="_blank" className={elementClassName}>{innerHtml}</a>
  ) : (
    <Link to={slashLink(hit.url)} className={elementClassName}>{innerHtml}</Link>
  )
}

export default InlineHit